import { Injectable, signal } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as localforage from 'localforage';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    readonly config = signal(undefined);
    backgroundImage: string;
    backgroundVideo: string;
    readonly flexGrow = signal(undefined);
    readonly width = signal(undefined);

    readonly configLoading = signal(true);

    private readonly keywords = [
        'cook',
        'gis',
        'viewer',
        'geo',
        'gisarts',
        'kaart'
    ];

    private description =
        'De COOK Geo Viewer is de snelste van Nederland en daar zijn we trots op. Naast snel, zijn onze viewers veilig, compleet en gepersonaliseerd. Wij werken met open source voor onze geo-registratie en publicatie, dat geeft een sterke fundering. Want hierdoor kunnen wij informatiestromen uit verschillende systemen raadplegen, combineren en inzichtelijk maken. Welke kaartlaag u (geanalyseerd) wilt zien bepaalt u zelf.';

    constructor(
        private readonly http: HttpClient,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly titleService: Title,
        private readonly metaService: Meta
    ) {
        let backgroundImages = 1;
        let mobile: string;

        this.width.set(window.innerWidth);
        if (this.width() <= 768) {
            mobile = 'Mb';
            this.flexGrow.set(1);
        } else {
            mobile = 'Pc';
            this.flexGrow.set(0.2);
        }

        backgroundImages = mobile == 'Mb' ? 2 : 2;
        const randomNumber: number = Math.floor(
            Math.random() * backgroundImages + 1
        );
        this.backgroundImage = `${environment.subDirectory}/assets/img/background/background${randomNumber}${mobile}.webp`;
    }

    private setConfig(newConfig: any) {
        if (typeof newConfig !== 'object') {
            console.error('Config is not an object');
            console.error(newConfig);
            this.authService.logout();
            return;
        }

        this.config.set(newConfig);
        localforage.setItem('config', this.config());
    }

    getConfiguration(id?: any): void {
        this.configLoading.set(true);        
        if (navigator.onLine) {
            localforage.getItem('config').then((value: any) => {
                if (value) {
                    if (
                        !environment.public &&
                        value.id == localStorage.getItem('config_id')
                    ) {
                        this.setConfig(value);
                        this.configLoading.set(false);
                    } else {
                        this.loadConfiguration(id);
                    }
                } else {
                    this.loadConfiguration(id);
                }
            });
        } else {
            this.loadConfigFromStorage();
        }
    }

    setTitle(): void {
        const configName = this.config()?.name;
        this.titleService.setTitle(
            configName ? `COOK - ${configName}` : 'COOK'
        );

        // Set meta tags for SEO
        if (this.config()?.public) {
            const newKeywords = [...this.keywords];

            const publicName = this.config()?.public_name;

            if (publicName) {
                newKeywords.push(publicName);
            }

            const info = this.config()?.info;

            if (info?.description) {
                this.description = info.description;
            }

            this.metaService.updateTag({
                name: 'description',
                content: this.description
            });

            const content = newKeywords.join(', ').trim().replace(/,$/, '');

            this.metaService.updateTag({
                name: 'keywords',
                content: content
            });
        }
    }

    loadConfiguration(id?: number): void {
        this.getConfigObservable(id).subscribe(
            response => {
                this.setConfig(response.body);
                this.configLoading.set(false);
            },
            error => {
                if (error.status === 401 || error.status === 419) {
                    this.authService.logout();
                }
                this.configLoading.set(false);
            }
        );
    }

    private getConfigObservable(id?: number): Observable<any> {
        const url = id
            ? `${environment.api_base_url}/configuration/${id}`
            : `${environment.api_base_url}/configuration`;

        return this.http
            .get(url, { responseType: 'json', observe: 'response' })
            .pipe(
                catchError(error => {
                    // Handle any error during the HTTP request
                    return throwError(error);
                })
            );
    }

    private loadConfigFromStorage(): void {
        // Get config from localforage
        localforage.getItem('config').then(value => {
            if (value) {
                this.config.set(value);
                this.configLoading.set(false);
            }
        });
    }

    loadPublicConfiguration(name: string): void {
        const organisation = environment.loginPrefix.replace('_', '');
        // Get the public configuration
        this.http
            .get(`${environment.api_base_url}/public/${organisation}/${name}`, {
                responseType: 'json'
            })
            .subscribe({
                next: response => {
                    this.initializeCookieBanner();
                    this.setConfig(response);
                    this.configLoading.set(false);
                    environment.public = true;
                },
                error: () => {
                    environment.public = false;
                    this.router.navigateByUrl('/');
                    this.getConfiguration();
                }
            });
    }

    getConfigurationByParam(paramId: number): void {
        const url = `${environment.api_base_url}/configuration`;
        const body = { sharedId: paramId };

        this.http
            .get(url, {
                params: body
            })
            .subscribe({
                next: response => {
                    this.setConfig(response);
                    this.configLoading.set(false);
                },
                error: error => {
                    console.error(error);
                    this.router.navigateByUrl('/');
                    this.getConfiguration();
                }
            });
    }

    private initializeCookieBanner(): void {
        const isCookieAccepted = localStorage.getItem('isCookieAccepted');
        if (isCookieAccepted === null) {
            localStorage.setItem('cb_isCookieAccepted', 'no');
            this.showCookieBanner();
        }
        if (isCookieAccepted === 'no') {
            this.showCookieBanner();
        }
    }

    private showCookieBanner(): void {
        const cookieBanner = document.getElementById('cookie-banner');
        cookieBanner.style.display = 'block';
    }

    hideCookieBanner(): void {
        localStorage.setItem('isCookieAccepted', 'yes');

        const cookieBanner = document.getElementById('cookie-banner');
        cookieBanner.style.display = 'none';
    }
}
